@use 'sass/forward' as *;

// estilos del carrusel
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
// fuentes
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&family=Raleway:wght@300;600&display=swap");

html {
  background-color: #ffffff;
  font-size: clamp(1.1rem, 0.8rem, 4vw);
  scroll-behavior: smooth;
}

body {
  font-family: Oxygen;
  font-weight: 300;
  position: relative;
  color: $azulOscuro;
}
h1,
h2,
h3,
h4 {
  font-family: Raleway;
  font-weight: 300;
  b {
    font-weight: 600;
  }
}
#cuerpo {
  overflow: hidden;
}

.h2sepa {
  font-family: Oxygen;
  font-size: 1vw;
  @include cel {
    font-size: clamp(1.8vh, 0.6vw, 2vw);
  }
  letter-spacing: 0.2rem;
  text-align: center;
  text-transform: uppercase;
}
.h2sepaizq {
  font-family: Oxygen;
  font-size: 1vw;
  @include cel {
    font-size: clamp(2vh, 0.7vw, 2.2vw);
  }
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}
h2 {
  font-size: clamp(4.5vh, 3vw, 5vw);
}
.paddArriba {
  padding-top: 5rem;
}
.paddAbajo {
  padding-bottom: 3rem;
}

.minmax {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  // grid-template-columns: repeat(8, 1fr);
  margin-bottom: 9rem;
  @include cel {
    margin-bottom: 0rem;
  }
}

.conte {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 5vw;
}

.porqueHome {
  position: relative;
  z-index: 400;
  background: url("/img/Porque-home-icza.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  grid-column: 1/2;
  position: relative;

  ul {
    padding: 10vw 0;
    li {
      padding: 0.7em 0 0;
    }
  }
  img {
    position: absolute;
    bottom: -18%;
    left: 20%;
    max-width: 150px;
  }
  @include cel {
    grid-column: 1/8;
    height: 30vh;
    img {
      width: 25%;
      bottom: -18%;
      left: 0;
    }
  }

  @include hd {
    height: 25vw;
  }
}
.porqueContacto {
  margin-top: 4em;
  position: relative;
  z-index: 400;
  background: url("/img/fondo-servicios-azul-1@2x.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  grid-column: 1/2;
  position: relative;
  a{color:$azulMuyClaro;}

  ul {
    padding: 10vw 0;
    @include cel {
      padding: 15vw;
    }
    li {
      padding: 0.7em 0 0;
    }
  }
  img {
    position: absolute;
    bottom: -18%;
    left: 20%;
    max-width: 150px;
  }
  @include cel {
    margin-top: 0em;
    grid-column: 1/8;
    min-height: 40vh;
    img {
      width: 25%;
      bottom: -18%;
      left: 0;
    }
  }

  @include hd {
    height: 25vw;
  }
}

.bandejaTxt {
  display: flex;
  padding: 3em 0;
  justify-content: center;
  align-items: center;
  div {
    width: 60%;
    @include cel {
      width: 70%;
    }
  }

  li {
    margin-left: 1.1rem;
  }
  h3,
  li,
  p {
    margin-bottom: 2em;
  }
  p:last-child {
    margin-bottom: 0em;
  }
  li:last-child {
    margin-bottom: 0em;
  }

  grid-column: 0/-1;
  @include cel {
    grid-column: 1/-1;
  }
  @include cel {
    order: 1;
    margin-top: 2rem;
  }
}

#consultoria {
  h2 {
    text-align: center;
    @include cel {
      padding: 1em 2em 0;
    }
  }
}

#caso {
  position: relative;
  background-color: $azulMuyClaro;
  background: linear-gradient(90deg, #ffffff 25%, $azulMuyClaro 25%);
  @include cel {
    background: $azulMuyClaro;
    margin-bottom: 5rem;
  }
  h2 {
    text-align: left;
    padding-bottom: 1rem;
  }
  p {
    margin-bottom: 0.6rem;
  }
}

#casoInvertido {
  position: relative;
  background-color: $azulMuyClaro;
  background: linear-gradient(90deg, $azulMuyClaro 75%, #ffffff 25%);
  @include cel {
    background: $azulMuyClaro;
    margin-bottom: 5rem;
  }
  h2 {
    text-align: left;
    padding-bottom: 1rem;
  }
  p {
    margin-bottom: 0.6rem;
  }
}

.estilotxt {
  font-size: clamp(2vh, 1.5vw, 1.8vw);
  @include cel {
    font-size: 1.2em;
  }
}

#conteImaCaso {
  position: relative;
  transform: translateY(-3rem);
  -webkit-transform: translateY(-3rem);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  @include cel {
    grid-column: 1/-1;
    transform: translateY(0rem);
    height: 70vh;
    img {
      height: 100%;
    }
  }
}
#jorge {
  position: absolute;
  right: 0;
  bottom: 5px;
  background-color: $azulOscuro;
  color: #ffffff;
  padding: 2.5rem;
  width: 50%;
  opacity: 0.9;
  @include cel {
    width: 100%;
    bottom: 0;
  }
}

#gridlogos {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @include cel {
    grid-template-columns: repeat(3, 1fr);
  }
  div {
    display: flex;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    @include cel {
      padding: 1em;
    }
    img {
      width: 100%;
      max-width: 150px;
      height: auto;
    }
  }
}
#metodologia {
  padding: 5rem 0;
  background: url("img/fondo-metodologia-icza-way-2.jpg") no-repeat;
  background-size: 100%;
  background-position: center bottom;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  .h2sepa {
    flex: 1 0 100%;
  }
  @include cel {
    background-size: 500%;
    h2 {
      text-align: left;
    }
    .h2sepa {
      padding-left: 3rem;
    }
  }
  .minmax {
    margin-bottom: 0%;
  }
  .iczaWay {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    @include cel {
      grid-column: 2/-1;
      justify-content: left;
      padding-top: 1rem;
    }
  }
  .bandejaTxt {
    div {
      width: 80%;
    }
    justify-content: left;
    @include cel {
      padding-top: 0;
      div {
        width: 70%;
      }
      justify-content: center;
      .btnAzul {
        text-align: center;
      }
    }
  }
}

#footer {
  h3 {
    text-align: left;
    @include cel {
      text-align: center;
    }
  }
  p {
    font-size: 0.9rem;
    padding-top: 1rem;
  }
  #social {
    li {
      display: inline-block;
      width: 60px;
      padding: 1rem 1rem 0 0;
    }
  }
  .minmax {
    margin-bottom: 5rem;
    #logoPie {
      max-width: 220px;
    }
    @include cel {
      div {
        grid-column: 1/-1;
        text-align: center;
        margin-top: 1.5rem;
      }
      #logoPie {
        margin: 0 5rem;
      }
    }
    div + div {
      display: flex;
      justify-content: center;
    }
  }
  #legales {
    margin-bottom: 2rem;
    border-top: solid 1px $azulOscuro;
    display: flex;
    justify-content: space-between;
    @include cel {
      display: block;
      text-align: center;
    }
  }
}

#serviciosDivfinal {
  background: url("img/fondo-servicios-e-gris@2x.jpg") no-repeat;
  background-size: 100%;
  background-position: center bottom;
  padding-bottom: 3em;
}

#mapa {
  background-color: $azulClaro;
  height: 35vw;
  margin-top: -18vw;
  z-index: 0;
  @include hd {
    margin-top: -10vw;
  }
  @include cel {
    margin-top: 0;
    height: 40vh;
  }
}

@mixin cliente($cliente2) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100px auto 1fr 100px;
  
  @include cel{
    grid-template-rows: 100px auto 1fr 10px;
  }

  h2{
    font-size: clamp(1.8vh, 1.8vw, 2vw);
    @include cel{
      font-size: 1.8em;
    }
  }

  .logo {
    @include cel {
      grid-column: 1 / -1;
      grid-row: 1 / span 2;
      min-height: 45vh;
      padding: 10vh 0;
    }

    -webkit-box-shadow: 1em 20px 0px 5px $azulClaro;
    box-shadow: 1em 20px 0px 5px $azulClaro;
    @include cel {
      -webkit-box-shadow: calc(#{4em}/ 2) 20px 0px 5px $azulClaro;
      box-shadow: calc(#{4em}/ 2) 20px 0px 5px $azulClaro;
    }


    grid-column: 9 / span 4;
    grid-row: 1 / span 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: space-evenly;
    background-color: $azulMuyClaro;
    min-height: 30vw;
    img {
      flex: 1 0 auto;
      max-width: 250px;
      padding: 1em;
      mix-blend-mode: multiply;
    }
  }
  .conte {
    grid-column: 2 / span 8;
    grid-row: 2 / span 3;
    color: #ffffff;
    background-color: $azulOscuro;
    display: flex;
    justify-content: center;
    align-items: center;

    @include cel {
      grid-column: 1 / -1;
      grid-row: 3 / span 2;
      padding: 20% 15%;
    }

    h2 {
      flex: 1 0 100%;
    }
    p {
      flex: 1;
      margin-bottom: 1em;
    }
    b {
      display: block;
      padding: 1em 0;
      text-transform: uppercase;
    }
  }

  @if $cliente2 == false {
    .conte{
      padding: 10% 20% 10% 10%;
      @include cel {
        grid-column: 1 / -1;
        grid-row: 3 / span 2;
        padding: 20% 15%;
      }
    }
  }
  @if $cliente2 {
    align-items: none;
    .logo {
      grid-column: 1 / span 4;
      grid-row: 2 / span 1;
      @include cel {
        grid-column: 1 / -1;
        grid-row: 2 / span 1;
      }
    }
    .conte {
      grid-column: 6 / span 6;
      grid-row: 2 / span 1;
      background-color: #ffffff;
      color: $azulOscuro;
      padding: 10% 0;

      @include cel {
        grid-column: 1 / -1;
        grid-row: 3 / span 1;
        padding: 20% 15%;
      }
    }
  }
}

.cliente {
  @include cliente($cliente2: false);
}
.cliente2 {
  @include cliente($cliente2: true);
}

#elh1{
  display: none;
}