@use 'mediaQ' as *;
@use 'color' as *;
// Boton toogle
.menu-toggle {
  position: fixed;

  display: none;
  @include cel {
    display: block;
    z-index: 99000;
  }
  right: 4vh;
  top: 4.6vh;
  z-index: 520;
  width: 30px;
  height: 22px;
  background: transparent;
  border-top: 4px solid;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 4px solid;
  color: $azulOscuro;
  font-size: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.menu-toggle:before,
.menu-toggle:after {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

button.is-active {
  border-color: transparent;
  border-left: none;
}
button.is-active:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
button.is-active:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

button {
  border: none;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
}

// menu de navegación
nav {
  // mix-blend-mode:saturation;
  @include cel {
    background-color: #fff;
    a {
      color: $azulOscuro !important;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 99999;
  a {
    color: #fff;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
  }
  .logo {
    padding: 1em;
    img {
      width: 150px;
      height: auto;
      margin-left: 6em;
      @include tableta_land {
        margin-left: 2em;
      }
      @include cel {
        margin-left: 0em;
      }
    }
  }
  ul {
    padding: 0 4em;
    display: flex;
    li {
      padding: 0 2em;
      .activo {
        color: $azulOscuro;
      }
    }
  }

  @include cel {
    flex-wrap: wrap;
    align-items: flex-start;
    height: 85px;
    .logo {
      align-self: flex-start;
    }
    ul:nth-child(3n) {
      li {
        padding: 1.5em 0;
      }
    }

    ul:nth-child(2n) {
      display: flex;
      justify-content: center;
    }

    ul {
      flex: 1 0 100%;
      visibility: hidden;
      display: none;
      padding: 2em;
    }
  }
  transition: all 300ms ease-in-out;
}

.scroll-down {
  transform: translate3d(0, -100%, 0);
}

.scroll-up {
  background-color: $gris;
  a {
    color: $azulOscuro;
  }
  .activo {
    font-weight: bold;
  }
}

@include cel {
  nav + section {
    padding-top: 80px;
  }
}

#social {
  svg {
    max-width: 30px;
    fill: $azulOscuro;
  }
}

// aqui todo lo que tenga que ver con el slide

.glide , .HeroInt {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}
.glide__slide, .BandejaHero {
  position: relative;
  @include cel {
    height: 80vh !important;
    img {
      object-fit: cover;
      height: 100%;
      width: auto;
    }
  }
  .bandeja {
    width: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 10%;
    position: absolute;
    color: #fff;
    font-family: Raleway;
    font-size: clamp(1.3rem, 2vw, 4vw);
    @include cel {
      width: 70%;
    }
  }
}

.animapuntos {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 200px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  @include cel {
    top: 30px;
    width: 130px;
    height: 130px;
  }
  .punto {
    width: 5px;
    height: 5px;
    @include cel {
      width: 4px;
      height: 4px;
    }
    border-radius: 5px;
    background-color: $azulClaro;
    margin-right: 5px;
  }
}
@mixin boton($azul) {
  display: inline-block;
  @if $azul {
    background-color: $azulOscuro;
    color: #fff;
  } @else {
    background-color: #fff;
    color: $azulOscuro;
  }
  font-family: Oxygen;
  // font-skize: clamp(0.6rem, 0.8rem, 1.5rem);
  font-size: clamp(1.6vh, 2.5vw, 0.5vw);
  letter-spacing: 0.2rem;
  padding: 1rem 2.2rem;
  margin: 2em 0;
}

.btn {
  @include boton(false);
}
.btnAzul {
  @include boton(true);
}

@mixin puntos() {
  position: absolute;
  z-index: 999;
  max-width: clamp(8%, 10%, 12%);
  height: auto;
  right: 1%;
  bottom: 5%;
}
.dots {
  @include puntos;
}

.dots-hero {
  position: absolute;
  z-index: 999;
  max-width: clamp(50%, 45%, 85%);
  @include cel{
    max-width: 150%;
  }
  height: auto;
  right: 0%;
  bottom: 0.6%;
}

#controles {
  position: absolute;
  z-index: 8888;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 50px;
  button {
    background: none;
  }
  img {
    width: 40px;
  }
}

@mixin imagenes($sombra, $fondo, $colorSombra) {
  display: flex;
  justify-content: center;
  position: relative;
  @if $fondo != null {
    background: url($fondo) no-repeat;
    background-size: cover;
    min-height: 25vw;
    -webkit-box-shadow: $sombra 50px 0px 5px $colorSombra;
    box-shadow: $sombra 50px 0px 5px $colorSombra;
    display: flex;
    justify-content: center;
    align-items: center;
    @include cel {
      padding: 2em;
      -webkit-box-shadow: calc(#{$sombra}/ 2) 30px 0px 5px $colorSombra;
      box-shadow: calc(#{$sombra}/ 2) 30px 0px 5px $colorSombra;
    }
  }

  .foto {
    height: auto;
    object-fit: cover;
    -webkit-box-shadow: $sombra 50px 0px 5px $colorSombra;
    box-shadow: $sombra 50px 0px 5px $colorSombra;
    @include cel {
      -webkit-box-shadow: calc(#{$sombra}/ 2) 30px 0px 5px $colorSombra;
      box-shadow: calc(#{$sombra}/ 2) 30px 0px 5px $colorSombra;
    }
  }

  @include cel {
    order: 0;
    grid-column: 1/-1;
    margin-top: 2rem;
  }
}
.conteIma {
  @include imagenes(10em, null, $azulClaro);
}
.conteImaIzq {
  @include imagenes(-10em, null, $azulClaro);
}
.conteImaServicio1 {
  @include imagenes(10em, "img/fondo-servicios-e-gris-1.jpg", $azulClaro);
  @include cel{
    padding:0.5em!important;
  }
  img{
    padding:3em;
    @include cel{
      padding:0em!important;
    }
  }
}

.conteImaServicio2 {
  @include imagenes(
    -10em,
    "img/fondo-servicios-casos-de-negocios.jpg",
    $azulOscuro
  );
  img{
    padding:3em;
    @include cel{
      padding:0em!important;
    }
  }
}

.conteImaServicio3 {
  @include imagenes(10em, "img/fondo-servicios-azul-1@2x.jpg", $azulClaro);
  img{
    padding:3em;
    @include cel{
      padding:0em!important;
    }
  }
}

.conteImaServicio4 {
  @include imagenes(-10em, "img/fondo-servicios-e-gris-2@2x.jpg", $azulOscuro);
  img{
   opacity: 0.7;
  }
  @include cel {
    img {
      transform: translateY(-20px);
      -webkit-transform: translateY(-20px);
      padding: 2em;
      mix-blend-mode: multiply;
    }
  }
}

.conteImaServicio5 {
  @include imagenes(10em, "img/fondo-servicios-e-gris-2@2x.jpg", $azulClaro);
  img{
    padding:6em;
    opacity: 0.7;
    @include cel{
      padding:0em!important;
    }
  }
  @include cel {
    img {
      transform: translateY(-20px);
      -webkit-transform: translateY(-20px);
      padding: 1em;
      mix-blend-mode: multiply;
    }
  }
}

.conteImaServicio6 {
  @include imagenes(-10em, null, #ffffff);
}

// Mixin de puntos

@mixin punto($arriba, $izq) {
  position: absolute;
  top: $arriba;
  left: $izq;
  max-width: 8vw;
  @include cel {
    max-width: 25%;
  }
}

.puntosArriba {
  @include punto(-20%, 0%);
}
.puntosArribaDer {
  @include punto(-20%, 74%);
}
.puntosAbajo {
  @include punto(80%, 0%);
}
.puntosAbajoDer {
  @include punto(80%, 74%);
}
.puntosCasoExito {
  @include punto(86%, 85%);
  @include cel {
    @include punto(95%, 74%);
  }
}

@mixin banner($fondo) {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vw;
  @include cel {
    height: 70vh;
  }
  background: url($fondo) no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  text-align: center;
  margin-bottom: 5rem;
  .h2sepa {
    padding-bottom: 1rem;
  }
  p {
    margin: 0 20%;
    font-weight: bold;
    font-size: 1.4em;
    font-family: Raleway;
    @include cel {
      font-size: initial;
    }
  }
}
.bannerHome {
  @include banner("/img/fondo-maximo-potencial-icza.jpg");
}
.bannerNosotros {
  @include banner("/img/Nosotros-fondo-cta.jpg");
}

.bannerServicios {
  @include banner("/img/servicio-fondo-cta.jpg");
}

.bannerClientes {
  @include banner("/img/clientes-fondo-cta.jpg");
}

.bannerContacto {
  @include banner("/img/contacto-fondo-cta.jpg");
}
@mixin sectionIntro($fondo) {
  margin-top: 0;
  background: url($fondo) no-repeat;
  background-position: center right;
  background-size: contain;
  min-height: 30vw;
  display: grid;
  grid-template-columns: 10% 1fr 1fr;
  @include cel {
    grid-template-columns: 15% 1fr 15%;
    background-position: top center;
    background-size: 120%;
    padding: 15vw 0;
  }
  justify-content: center;
  align-content: center;
  margin-top: -5px;
  div {
    grid-column: 2;
    padding: 10vw 0;

    p {
      padding-bottom: 2em;
    }
  }
}

#nosotrosIntro {
  @include sectionIntro("/img/fondo-gris-nosotros.jpg");
}
#clientesIntro {
  @include sectionIntro("/img/fondo-clientes-gris.jpg");
}
#serviciosIntro {
  @include sectionIntro("/img/fondo-servicios-a.jpg");
}

@mixin cfa($fondo, $abajo) {
  background: url($fondo) no-repeat;
  background-size: cover;
  @if $abajo {
    transform: translateY(3rem);
    -webkit-transform: translateY(3rem);
  } @else {
    transform: translateY(-3rem);
    -webkit-transform: translateY(-3rem);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Raleway;
  min-height: 30vw;
  @include cel {
    min-height: 30vh;
  }
  @include cel {
    grid-column: 1/-1;
    transform: translateY(0rem);
    -webkit-transform: translateY(0rem);
  }
  p {
    color: #fff;
    font-size: clamp(2vh, 1.5vw, 1.8vw);
    width: 70%;
    padding: 10vw 0;
    @include cel {
      font-size: 1.2em;
      padding: 22vw 0;
    }
  }
}

.cfaNosotros {
  @include cfa("/img/Nosotros-fondo-texto.jpg", false);
}
.cfaServicios {
  @include cfa("/img/Servicios-icza-problem-solving.jpg", false);
}
.cfaServicios2 {
  @include cfa("/img/Servicios-icza-definir-gestionar.jpg", true);
}
.cfaServicios3 {
  @include cfa("/img/Servicios-icza-orden.jpg", false);
}
#cargando {
  position: absolute;
  z-index: 999999;
  background-color: $azulOscuro;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  img{
    max-width: 200px;
  }
}
.eliminado {
  display: none !important;
}
